import { RouteInfo } from "./sidebar.metadata";

const role = localStorage.getItem('role')
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["Superadmin"],
    submenu: [],
  },
  // *********admin***********
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["Teacher"],
    submenu: [],
  },
  // Dashborad
   // *********Teacher***********
  {
    path: "/admin/dashboard/main",
    title: "MENUITEMS.DASHBOARD.TEXT",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Superadmin"],
    submenu: [],
  },
  {
    path: "/admin/dashboard/main",
    title: "MENUITEMS.DASHBOARD.TEXT",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/admin/dashboard/main",
    title: "MENUITEMS.DASHBOARD.TEXT",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Teacher"],
    submenu: [],
  },
  
  
  // School
  
  {
    path: "/admin/school/all-school",
    title: "School",
    iconType: "material-icons-two-tone",
    icon: "school",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Superadmin"],
    submenu: [],
  },

  {
    path: "/admin/school/schoolDetails",
    title: "School",
    iconType: "material-icons-two-tone",
    icon: "school",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  // ******games******
  // ********superadmin******
  {
    path: "/admin/games/all-games",
    title: "Games",
    iconType: "material-icons-two-tone",
    icon: "games",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Superadmin"],
    submenu: [],
  },
  // ********admin******
  {
    path: "/admin/games/all-games",
    title: "Games",
    iconType: "material-icons-two-tone",
    icon: "games",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  // ********teacher******
  {
    path: "/admin/games/all-games",
    title: "Games",
    iconType: "material-icons-two-tone",
    icon: "games",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Teacher"],
    submenu: [],
  },
  // Master
  // **************superadminadmin************
  {
    path: "/",
    title: "Masters",
    iconType: "material-icons-two-tone",
    icon: "business",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Superadmin"],
    submenu: [
      {
        path: "/admin/master/classes/all-classes",
        title: "Class",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/level/all-level",
        title: "Level",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/section/all-section",
        title: "Section",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/subject/all-subject",
        title: "Subject",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/exam/all-exam",
        title: "Exam",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/designation/all-designation",
        title: "Designation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/role/all-role",
        title: "Role",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/questions/all-questions",
        title: "Questions",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/game/all-game",
        title: "Game",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  // **************admin************
  {
    path: "/",
    title: "Masters",
    iconType: "material-icons-two-tone",
    icon: "business",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/master/classes/all-classes",
        title: "Class",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/level/all-level",
        title: "Level",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/section/all-section",
        title: "Section",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/subject/all-subject",
        title: "Subject",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/exam/all-exam",
        title: "Exam",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/designation/all-designation",
        title: "Designation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/role/all-role",
        title: "Role",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/questions/all-questions",
        title: "Questions",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/master/game/all-game",
        title: "Game",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

   // **************teacher************
  //  {
  //   path: "/",
  //   title: "Masters",
  //   iconType: "material-icons-two-tone",
  //   icon: "business",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Teacher"],
  //   submenu: [
  //     {
  //       path: "/admin/master/classes/all-classes",
  //       title: "Class",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/master/level/all-level",
  //       title: "Level",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/master/section/all-section",
  //       title: "Section",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/master/subject/all-subject",
  //       title: "Subject",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/master/exam/all-exam",
  //       title: "Exam",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/master/designation/all-designation",
  //       title: "Designation",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/master/role/all-role",
  //       title: "Role",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/master/questions/all-questions",
  //       title: "Questions",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/master/game/all-game",
  //       title: "Game",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },

  // Staff
  {
    path: "/admin/staff/all-staff",
    title: "Staff",
    iconType: "material-icons-two-tone",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Superadmin"],
    submenu: [],
  },
  {
    path: "/admin/staff/all-staff",
    title: "Staff",
    iconType: "material-icons-two-tone",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },

  {
    path: "/admin/staff/staffdetails",
    title: "Staff",
    iconType: "material-icons-two-tone",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Teacher"],
    submenu: [],
  },

  // Students
  {
    path: "/admin/students/all-students",
    title: "Students",
    iconType: "material-icons-two-tone",
    icon: "people_alt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Superadmin"],
    submenu: [],
  },
  {
    path: "/admin/students/all-students",
    title: "Students",
    iconType: "material-icons-two-tone",
    icon: "people_alt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/admin/students/all-students",
    title: "Students",
    iconType: "material-icons-two-tone",
    icon: "people_alt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Teacher"],
    submenu: [],
  },
  // School Setting
  {
    path: "/admin/school",
    title: "School Setting",
    iconType: "material-icons-two-tone",
    icon: "school",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Superadmin"],
    submenu: [
      {
        path: "../../admin/staff-allotment/all-staff-allotment",
        title: "Staff Allotment",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/mark-allotment/all-mark-allotment",
      //   title: "Mark Allotment",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      {
        path: "/admin/mark-allot/mark-allot",
        title: "Mark Allotment",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  {
    path: "/admin/school",
    title: "School Setting",
    iconType: "material-icons-two-tone",
    icon: "school",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "../../admin/staff-allotment/all-staff-allotment",
        title: "Staff Allotment",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/mark-allotment/all-mark-allotment",
      //   title: "Mark Allotment",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      {
        path: "/admin/mark-allot/mark-allot",
        title: "Mark Allotment",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  {
    path: "/admin/school",
    title: "School Setting",
    iconType: "material-icons-two-tone",
    icon: "school",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Teacher"],
    submenu: [
      
      {
        path: "/admin/mark-allot/mark-allot",
        title: "Mark Allotment",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // Reports
  // {
  //   path: "",
  //   title: "Reports",
  //   iconType: "material-icons-two-tone",
  //   icon: "widgets",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/widget/chart-widget",
  //       title: "Parametrics",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/widget/data-widget",
  //       title: "Analytics",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },

  // For Authentication
  // {
  //     path: '',
  //     title: 'Authentication',
  //     iconType: 'material-icons-two-tone',
  //     icon: 'supervised_user_circle',
  //     class: 'menu-toggle',
  //     groupTitle: false,
  //     badge: '',
  //     badgeClass: '',
  //     role: ['Admin'],
  //     submenu: [
  //     {
  //         path: '/authentication/signin',
  //         title: 'Sign In',
  //         iconType: '',
  //         icon: '',
  //         class: 'ml-menu',
  //         groupTitle: false,
  //         badge: '',
  //         badgeClass: '',
  //         role: [''],
  //         submenu: [],
  //     },
  //     {
  //         path: '/authentication/signup',
  //         title: 'Sign Up',
  //         iconType: '',
  //         icon: '',
  //         class: 'ml-menu',
  //         groupTitle: false,
  //         badge: '',
  //         badgeClass: '',
  //         role: [''],
  //         submenu: [],
  //     },
  //     {
  //         path: '/authentication/forgot-password',
  //         title: 'Forgot Password',
  //         iconType: '',
  //         icon: '',
  //         class: 'ml-menu',
  //         groupTitle: false,
  //         badge: '',
  //         badgeClass: '',
  //         role: [''],
  //         submenu: [],
  //     },
  //     {
  //         path: '/authentication/locked',
  //         title: 'Locked',
  //         iconType: '',
  //         icon: '',
  //         class: 'ml-menu',
  //         groupTitle: false,
  //         badge: '',
  //         badgeClass: '',
  //         role: [''],
  //         submenu: [],
  //     },
  //     {
  //         path: '/authentication/page404',
  //         title: '404 - Not Found',
  //         iconType: '',
  //         icon: '',
  //         class: 'ml-menu',
  //         groupTitle: false,
  //         badge: '',
  //         badgeClass: '',
  //         role: [''],
  //         submenu: [],
  //     },
  //     {
  //         path: '/authentication/page500',
  //         title: '500 - Server Error',
  //         iconType: '',
  //         icon: '',
  //         class: 'ml-menu',
  //         groupTitle: false,
  //         badge: '',
  //         badgeClass: '',
  //         role: [''],
  //         submenu: [],
  //     },
  //     ],
  // },
];
