export const authApi = {
  production: false,
  // baseURL: `http://localhost:4000`,
  // baseURL: `https://65.0.129.164.nip.io`,
  baseURL: `http://103.102.234.148:4001`,
};

// export const authApi = {
//   production: false,
//   baseURL: `https://65.0.129.164.nip.io`,
//   // baseURL: `http://65.2.128.212:4000`,
// };



